<template>
  <b-modal :visible="show" @hide="$emit('close')" size="lg" hide-header hide-footer centered>
    <div class="common-modal-headline">
      <h4 class="common-modal-title" id="doc">CareRide, Inc. - VENDOR AGREEMENT</h4>
      <span class="material-symbols-rounded common-modal-close" @click="$emit('close')">
        close
      </span>
    </div>
    <hr class="my-3" />
    <div class="agreements-content">

      <p>
        The vendor agreement is entered into and made Effective Date as of the date of approval by Careride Technologies,
        by and between Careride Technologies Inc, a Delaware corporation, and (Vendor Company at its designated location
        submitting the application)
      </p>

      <h5>1. APPOINTMENT; FULFILLMENT; LICENSE.</h5>
      <p>
        <b>1.1 Appointment and Term.</b> Subject to the terms and conditions of this Agreement, CareRide hereby appoints
        Vendor, and Vendor hereby accepts such appointment, to act as a non-exclusive Vendor to provide pre-scheduled,
        non-emergency medical transportation (“<u>NEMT</u>”) rides to customers (“<u>Rides</u>”) using CareRide's
        web-based scheduling platform at <a href="//www.careride.com">www.careride.com</a> or successor or affiliate sites
        (collectively, the
        “<u>Platform</u>”). This Agreement shall continue from the Effective Date (the “<u>Term</u>”) unless terminated by
        either party as provided in this Agreement, and will automatically renew for one (1) year periods thereafter,
        unless either party notifies the other party in writing of its intent not to renew not later than thirty (30)
        before the end of the Term.
      </p>
      <p>
        <b>1.2 Fulfillment.</b> Vendor will use commercially reasonable efforts to provide all Rides scheduled by
        customers for Vendor through the Platform.
      </p>
      <p><b>1.3 License.</b> Solely during the Term, and subject to the terms and conditions of this Agreement, CareRide
        hereby grants to Vendor, a non-exclusive, non-transferrable, revocable, license to (i) access the Platform to
        receive customer orders for Rides and (ii) use the trademark “<u>CareRide Technologies</u>” and such other
        trademarks as CareRide may advise Vendor in writing from time to time (the “<u>Trademarks</u>”) solely for the
        purpose of promoting Rides via the Platform</p>

      <h5>2. PERMITS AND LICENSES; TAXES.</h5>
      <p>
        <b>2.1 Permits.</b> Unless otherwise agreed in writing by the parties, Vendor, shall secure all permits and
        licenses, make all cash and other deposits, provide all bonds, and give all notices required either by law,
        regulation, ordinance, or permit in connection with scheduling and providing Rides.
      </p>
      <p>
        <b>2.2 Taxes.</b> Vendor shall pay, promptly when due, all license fees, duties, tariffs, and assessments and all
        sales, use, excise, import, export, re-export, and other taxes or charges (including any interest and penalties)
        payable in connection with Vendor's business, including taxes upon the wages of Vendor 's employees.
      </p>

      <h5>3. STAFFING; VEHICLES & EQUIPMENT, ETC.</h5>

      <p><b>3.1 Staffing.</b> In connection with providing Rides through the Platform, Vendor shall: (i) supply its own
        personnel of the type and number necessary to perform the work, (ii) ensure that its personnel have valid, current
        drivers licenses and (iii) valid auto insurance.</p>

      <p><b>3.2 Vehicles & Equipment.</b> Vendor shall, at its expense, furnish and maintain all supplies, tools,
        equipment, machines, and vehicles necessary for Vendor 's employees and contractors to provide Rides via the
        Platform. </p>

      <p><b>3.3 Protection.</b> Vendor shall be solely responsible for the safety of its employees, contractors,
        consultants and agents, as well as their respective customers, invitees and guests who schedule and/or provide
        Rides and/or utilize Rides scheduled through the Platform. Vendor shall take all necessary precautions to keep and
        maintain the supplies, tools, equipment, machines, and vehicles free from recognized hazards which are likely to
        cause death, illness or injury to persons or damage to property. </p>

      <h5>4. OWNERSHIP OF INTELLECTUAL PROPERTY (IP).</h5>
      <p>
        <b>4.1 Ownership.</b> Vendor acknowledges and agrees that CareRide retains all right, title, and interest in and
        to the Platform and all IP rights in the Platform including, without limitation, all patent rights, trademarks,
        trade secrets, designs, text, and any and all updates, changes, derivative works, and/or modifications thereto.
        Except as expressly set forth in writing herein, this Agreement does not grant Vendor any IP rights in the
        Platform or any right to continue providing Rides through the Platform beyond the end of the Term.
      </p>

      <h5>5. CONFIDENTIALITY; NONSOLICITATION. </h5>
      <p>
        <b>5.1 Confidential Information.</b> Vendor, on the one hand, and CareRide on the other hand, each possess certain
        proprietary and confidential information, some of which they desire to share in confidence with the other in order
        to perform their obligations under this Agreement. The term “<u>Confidential Information</u>” as used in this
        Agreement means any and all written, oral, visual, and electronic documentation and information obtained by one
        party (the “<u>Recipient</u>”) from the other party (the “<u>Providing Party</u>”), including but not limited to
        IP Rights; the terms of this Agreement; financial plans; business plans; operational methods; training methods;
        marketing strategies; product development techniques and plans; product specifications; customer lists; personnel
        information; and information received by the Providing Party from third parties to whom the Providing Party owes
        an obligation of confidentiality. “<u>Confidential Information</u>” does not include any information that is or
        becomes generally known to the public, other than by the Recipient's breach of any obligation owed to the
        Providing Party. A Recipient may disclose the Confidential Information of a Providing Party to law enforcement or
        government agencies if required by a subpoena or other compulsory legal process, provided that the Recipient gives
        the Providing Party written notice as soon as reasonably possible before disclosing such Confidential Information.
      </p>
      <p>
        <b>5.2 Use.</b> Recipient and Providing Party each agree they will not duplicate Confidential Information or
        provide it to any third party, except to those employees and contractors of Recipient who have a need to know and
        who are bound by a written agreement of confidentiality. Recipient and Providing Party each agree to use
        Confidential Information solely for the purpose of performing their duties under this Agreement. Recipient and
        Providing Party each agree that their respective Confidential Information derives independent economic value from
        not being readily known to others who can obtain economic value from its disclosure or use, that reasonable
        efforts have been made by the Providing Party to maintain the secrecy of such information, that such information
        is the sole property of the Providing Party and that any retention and use of such information by Recipient except
        in the course of performing its duties under this Agreement will constitute a misappropriation of Confidential
        Information.
      </p>
      <p>
        <b>5.3 Return of Property.</b> Upon the termination, or expiration of this Agreement, Vendor and CareRide each
        agrees to return all property of the other in their respective possession, if any, and not to retain any
        Confidential Information or provide it to any third party.
      </p>
      <p>
        <b>5.4 Goodwill.</b> Vendor acknowledges that CareRide has represented to Vendor that: (i) CareRide has expended
        and will continue to expend considerable time, effort and resources to develop and market its products and
        services and (ii) the relationships between CareRide and its employees, contractors, customers, vendors, and
        suppliers are valuable assets of CareRide and key to its success, all of which constitutes goodwill of CareRide (
        “<u>Goodwill</u>”).
      </p>
      <p>
        <b>5.5 Nonsolicitation of Employees and Customers.</b> In order for CareRide to maintain its Goodwill, during the
        Term and for a period of twelve (12) months thereafter, Vendor agrees not to: (i) directly or indirectly solicit,
        or hire, or attempt to solicit or hire, any person employed by CareRide (whether or not such employee is a
        full-time, contractual or temporary employee, and whether or not the employment is pursuant to a written
        agreement), or (ii) directly or indirectly, for itself or by aiding any other person or entity, solicit or divert
        or attempt to solicit or divert, any of CareRide's past, current or prospective customers or vendors from doing
        business with CareRide.
      </p>

      <p>
        <b>6. NOTICES.</b> Any and all notices and other communications required hereunder shall be in writing and shall
        be delivered personally or mailed by registered or certified mail, or documented overnight courier, return receipt
        requested, postage prepaid to the addresses set forth below (or such different addresses as may be designated by
        either party by written notice to the other party), or by facsimile or electronic mail (with confirming receipt
        and followed by a hard copy by first class mail), and shall be deemed given three (3) days after mailing, or in
        the case of overnight carrier, facsimile or email, the next business day at the address set forth below each
        party's signature to this Agreement.
      </p>

      <p>
        <b>7. INDEMNIFICATION.</b> Each of Vendor and CareRide agrees to indemnify, defend, protect and hold harmless the
        other and their respective shareholders, directors, officers, employees and agents from and against all claims,
        demands, liabilities, damages, actions, losses, costs and expenses (including reasonable attorney's fees and
        costs) (collectively, “<u>Losses</u>”) for injuries to or death of persons (Vendor's employees and contractors
        included)
        and injury to or for loss of property, for violation of applicable laws, rules and regulations, and for errors and
        omissions, negligence or willful misconduct arising from the activities contemplated by this Agreement, including
        scheduling and providing Rides. Notwithstanding the foregoing, the indemnification shall not apply with respect to
        any Losses that result from the sole negligence, gross negligence or willful misconduct of a party. In no event
        shall either party or any of its shareholders, directors, employees, or agents have any responsibilities with
        respect to business activities of the other party. If CareRide is required by any applicable Law to pay any
        workers' compensation premiums with respect to an employee or contractor of Vendor, then Vendor shall promptly
        reimburse CareRide for such payment. This indemnity provision shall survive all other obligations under this
        Agreement. Except with respect to a breach of Section 4 (IP Ownership) or Section 5 (Confidential Information), in
        no event shall either party or its employees, agents, affiliates, successors or assigns be liable to the other
        party, for any indirect, special, consequential, incidental, exemplary, or punitive damages, or expenses,
        including, but not limited to, loss of anticipated profits or other economic loss in connection with or arising
        out of the existence, furnishing, function, or use of any item of equipment, personnel, or service provided for in
        this Agreement, whether arising in contract, tort or otherwise. To the greatest extent permissible by applicable
        law and except for indemnified matters described herein, CareRide's aggregate liability to Vendor shall not exceed
        an amount equal to the revenues actually received by CareRide under this Agreement.
      </p>

      <p>
        <b>8. INSURANCE.</b> Insurance requirements are set forth on Annex A which is incorporated hereby by reference and
        made a part of this Agreement as if set forth fully herein; provided that CareRide reserves the right to modify,
        amend and/or update the insurance requirements upon not less than thirty (30) days prior written notice to Vendor.
      </p>

      <h5>9. REPRESENTATIONS AND WARRANTIES.</h5>
      <p>
        <b>9.1</b> Each of CareRide and Vendor represents and warrants to each other that it has it has full power and
        authority to enter into this Agreement and to fulfill its obligations hereunder.
      </p>
      <p>
        <b>9.2</b> Vendor further represents and warrants to CareRide that it will: (i) comply with all applicable laws,
        rules and regulations in connection with the execution of this Agreement, and the performance of its obligations
        and duties hereunder, (ii) comply with the Terms of Service and Privacy Policy on the Platform, to the extent
        applicable, and (iii) utilize the highest professional standards in the industry in providing Rides.
      </p>

      <h5>10. GENERAL.</h5>
      <p>
        <b>10.1 Independent contractors.</b> For the purpose of this Agreement, Vendor and CareRide are, and shall be
        acting as, independent contractors, and nothing herein or otherwise shall be construed as creating an
        employer-employee relationship, a partnership or a joint venture between the parties. Neither Vendor nor CareRide
        shall enter into any contract, agreement, arrangement or understanding in the name, or on behalf, of the other.
      </p>
      <p>
        <b>10.2 Payment.</b> Amounts due to Vendor for services provided to customers through CareRide will be made on a
        weekly basis. Payments will be made net of the a _fifteen_ Percent (15%) platform fee (“<u>Platform Fee</u>”) and
        _fifteen_ Percent (15%) service fee (“<u>Service Fee</u>”, and together with the Platform Fee, the “<u>CareRide
          Fees</u>”) which will be retained by CareRide in consideration of Vendor's access to the CareRide platform.
        Vendor acknowledges that CareRide Fees are subject to change on third (30) days prior notice to Vendor.
      </p>
      <p>
        <b>10.3 Assignment.</b> This Agreement may not be assigned by Vendor in whole or in part. This Agreement may be
        assigned to any division of CareRide or any parent or affiliates, or any purchaser of CareRide's business, whether
        by stock or asset purchase, merger or other similar transaction. This Agreement will be binding upon all of the
        successors and permitted assigns.
      </p>
      <p>
        <b>10.4 Governing Law; Jurisdiction.</b> This Agreement shall be governed by the laws of the State of California
        without regard to its conflict of laws' provisions. Any action or proceeding arising from or in connection with
        this Agreement shall be brought solely in the federal or state courts, or conducted in arbitration proceedings
        located in the State of California, County of Orange, and each of the parties hereto hereby consents to the
        jurisdiction of those venues for any such action or proceeding.
      </p>
      <p>
        <b>10.5 Publicity.</b> Neither party shall issue a press release without the written consent of the other party.
      </p>
      <p>
        <b>10.6 Force Majeure.</b> The performance of this Agreement by either party, in part or in full, is subject to
        unforeseeable acts beyond its reasonable control, including acts of God, war, government regulations, power
        outages, disaster, acts of terrorism, strikes (exception: neither party may terminate this Agreement for strikes
        involving their own employees), civil disorder, laws regarding pandemic/epidemic illness or outbreak that prevent
        any party from performing its obligations hereunder, or any other occurrence beyond the control of either party
        making it commercially impracticable, illegal, or impossible to perform under this Agreement (each, a “<u>Force
          Majeure Event</u>”). This contract may be terminated without a cancellation charge, or partially performed by
        CareRide or Vendor without a penalty due to any Force Majeure Event that lasts for longer than three (3) months.
        Any service rendered prior to the Force Majeure Event taking effect will be billed based on the actual hours spent
        completing previous tasks.
      </p>
      <p><b>10.7 Termination; Effect.</b></p>

      <p><b>10.7.1</b> This Agreement may be terminated by either party: (i) upon thirty (30) days prior written notice to
        the other party for any reason or no reason or (ii) by CareRide upon five (5 days) prior written notice of an
        uncured material breach by Vendor. </p>

      <p><b>10.7.2</b> This Agreement shall automatically and without notice terminate in the event of the bankruptcy,
        insolvency, dissolution or conviction of Vendor of a felony.</p>

      <p><b>10.7.3</b> Upon termination of this Agreement for any reason, the Licenses granted by CareRide in Section 1.2
        (License) shall immediately terminate and Vendor shall promptly cease using the all or any portion of the
        Trademarks and the Platform and shall return all tangible copies of marketing collateral, if any, to CareRide, and
        shall destroy and delete from all devices any electronic copies thereof (including, without limitation, any copies
        contained as an attachment or otherwise in any electronic communication).</p>


      <p><b>10.8</b> Amendment; Waiver. This Agreement may not be amended or any provision hereof waived except in a
        writing signed by CareRide and Vendor . The rights granted to the parties hereunder are cumulative and shall not
        constitute a waiver of any party's right to assert any other legal remedy available to it.</p>

      <p><b>10.9 Severability.</b> If any provision of this Agreement is declared by any court of competent jurisdiction
        to be invalid, illegal or incapable of being enforced, the remainder of such provision and of this Agreement shall
        continue in full force and effect.</p>

      <p><b>10.10 Injunctive Relief.</b> The parties acknowledge that any breach of Section 4 or Section 5 of this
        Agreement will result in substantial, continuing and irreparable injury to the non-breaching party. Accordingly,
        each party agrees that, in addition to any other remedy that may be available under this Agreement or by
        applicable law, the non-breaching party shall be entitled to obtain injunctive or other equitable relief by a
        court of appropriate jurisdiction, without posting a bond or proving actual damage, and shall further be entitled
        to recover any and all costs and expenses (including attorneys' fees) incurred by the non-breaching party in
        enforcing its rights under the aforementioned Sections of this Agreement.</p>

      <p><b>10.11 Survival of Provisions.</b> Notwithstanding anything to the contrary in this Agreement, Sections 4, 5, 7
        and 10 of this Agreement shall survive the termination of this Agreement.</p>

      <p><b>10.12 Dispute Resolution; Arbitration.</b></p>

      <p>
        <b>10.12.1</b> Except with respect to collection actions and pursuit of injunctive relief pursuant to Section
        10.10 (Injunctive Relief), before initiating any arbitration under Section 10.12.2 hereunder, the party asserting
        the dispute shall notify the other party in writing with a brief, written description of the dispute, including
        recommended resolution and contact information. The other party will have thirty (30) days from the date of
        receipt within which to attempt resolve the dispute to reasonable satisfaction. If the parties are unable to
        resolve the dispute through good faith negotiations over such thirty (30) day period under this informal process,
        either party may pursue resolution of the dispute via binding arbitration, which shall be the exclusive remedy
        hereunder for disputes not resolved by agreement within thirty (30) days.
      </p>

      <p>
        <b>10.12.2</b> Except with respect to collection actions and pursuit of injunctive relief pursuant to Section
        10.10 (Injunctive Relief) hereof, any controversies, claims, or disputes arising out of, relating to, or resulting
        from this Agreement not resolved in accordance with Section 10.12.1 hereof shall be subject to binding arbitration
        under the Arbitration Rules set forth in California Code of Civil Procedure Section 1280 through 1294.2, including
        Section 1283.05 (the “<u>Rules</u>”). Any arbitration will be administered by JAMS in accordance with its Rules
        for the Resolution of Commercial Disputes. Vendor agrees that the arbitrator shall have the power to decide any
        motions brought by any party to the arbitration, including motions for summary judgment and/or adjudication and
        motions to dismiss and demurrers, prior to any arbitration hearing. Vendor also agrees that the arbitrator shall
        have the power to award any remedies, including attorneys' fees and costs, available under applicable law. The
        decision of the arbitrator shall be in writing. Except as provided by the Rules, arbitration shall be the sole,
        exclusive and final remedy for any dispute under this Agreement. Accordingly, except as provided for by the Rules,
        neither Vendor , nor any franchisee or CareRide will be permitted to pursue court action regarding this Agreement.
      </p>

      <p>
        <b>10.13 Entire Agreement.</b> This Agreement contains the entire agreement of the parties with respect to the
        subject matter hereof and it supersedes any previous communications, contracts or agreements between the parties,
        whether written or oral.
      </p>

      <p>
        <b>10.14 Counterparts.</b> This Agreement may be executed in more than one counterpart, each of which shall be an
        original, and all of which shall constitute one and the same agreement.
      </p>

      <p>
        <b>IN WITNESS WHEREOF</b>, each of the parties has caused this Agreement to be duly executed on its behalf as of
        the date first set forth above.
      </p>

      <h5 class="text-center">
        ANNEX A <br />INSURANCE REQUIREMENTS
      </h5>

      <p>
        Vendor , on the one hand, and CareRide on the other hand, each agree, during the Term of this Agreement, to
        maintain, all necessary insurance, including, without limitation, liability and property damage insurance,
        including general liability, employment practices liability, workers compensation, products liability, personal
        injury, contractual liability, and automobile liability coverages, in the minimum amounts set forth in this Annex
        A (Insurance Requirements), and in such other amounts each determines will protect Vendor and CareRide from Losses
        for injuries to or death of persons, and for injury to or loss of property, arising directly or indirectly from
        Vendor providing Rides scheduled through the Platform to customers.
      </p>

      <p>
        <b>General Liability</b>. General liability and property damage and media liability insurance shall provide
        coverage on a per occurrence basis and shall have policy limits of not less than One Million Dollars
        ($1,000,000.00) per occurrence of bodily injury or death and property damage with not less than Two Million
        Dollars ($2,000,000.00) in the aggregate.
      </p>

      <p>
        <b>Motor Vehicle Liability</b>. Motor vehicle will be used in connection with Vendor 's obligations hereunder,
        Vendor shall secure and maintain in full force and effect comprehensive motor vehicle liability coverage for all
        owned, non-owned, and hired vehicles with bodily injury limits of not less than One Million Dollars
        ($1,000,000.00) per accident for bodily injury and property damage with no sub-limits below One Million Dollars
        ($1,000,000.00) for loading and unloading.
      </p>

      <p>
        <b>Workers Compensation</b>. Worker's Compensation insurance with statutory limits of coverage.
      </p>

      <p>
        <b>All Risk Property Insurance</b>. Vendor and each franchisee shall maintain all-risk property insurance with
        waiver of subrogation covering the full value of all property used by Vendor in providing Rides through the
        Platform.
      </p>

      <p>
        <b>Policy Requirements</b>. Each insurance policy required under this Annex A shall conform to the following
        requirements:
      </p>

      <ul>
        <li>The policy shall be issued by an insurance carrier rated A or higher by A.M. Best Co. </li>
        <li>All liability coverage shall be primary and non-contributory and, with the exception of workers compensation
          insurance, shall include a waiver of subrogation in favor of the Additional Insureds. </li>
        <li>The insurer shall issue an endorsement adding CareRide, Inc. as an Additional Insureds with respect to all
          coverage, with 30 days' notice of cancellation to Licensor be given by the insurer, or if the insurer does not
          customarily give such notice, then notice shall be given by Vendor.</li>
        <li>
          <b>Certificate of Insurance</b>. Prior to the Effective Date of this Agreement, Vendor shall provide CareRide: a
          certificate of insurance (“<u>COI</u>”), to be updated not less than once per every twelve (12) months, naming
          the Additional Insureds:
        </li>
        <li>
          <b>The COI shall include the endorsements listed below in substantially the forms attached to this
            Agreement:</b>

          <ul>
            <li>Additional Insured - Designated Person or Organization</li>
            <li>Waiver of Transfer of Rights of Recovery Against Others to Us</li>
          </ul>
        </li>
      </ul>

    </div>
</b-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss"></style>
